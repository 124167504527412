import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import appReducer from "../reducer/reducer";

// Combine your reducers
const rootReducer = combineReducers({
  helpers: appReducer,
  // Add more reducers as needed
});

// Configure middleware
const middleware = [thunk];

// Create the Redux store
const composeEnhancers = composeWithDevTools || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
