//@ts-ignore
import { SquareRadioButton } from "@viuti/recursos";
import { useEffect, useState } from "react";

const CheckBuilder = ({
  label,
  check = false,
  setForm = (e) => {},
  handleCheck = (e) => {},
}) => {
  const [checked, setChecked] = useState(check);

  useEffect(() => {
    if (handleCheck) handleCheck(checked);
    if (setForm) setForm((prev) => ({ ...prev, onlineReservation: checked }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => setChecked(check), [check]);

  const checkProps = {
    label,
    checked,
    setCheck: setChecked,
  };
  return <SquareRadioButton {...checkProps} />;
};

export default CheckBuilder;
