export const areEmployeesEqual = (obj1, obj2) => {
  const employees1 = obj1.employees || [];
  const employees2 = obj2.employees || [];

  if (employees1.length !== employees2.length) {
    return false;
  }

  for (let i = 0; i < employees1.length; i++) {
    const employee1 = employees1[i];
    const employee2 = employees2[i];

    if (employee1.id !== employee2.id || employee1.name !== employee2.name) {
      return false;
    }
  }

  return true;
};
