export const arePricesEqual = (obj1, obj2) => {
  const prices1 = obj1.prices || [];
  const prices2 = obj2.prices || [];

  if (prices1.length !== prices2.length) {
    return false;
  }

  for (let i = 0; i < prices1.length; i++) {
    const price1 = prices1[i];
    const price2 = prices2[i];

    if (
      price1.id !== price2.id ||
      price1.name !== price2.name ||
      price1.duration !== price2.duration ||
      price1.igv !== price2.igv ||
      price1.price !== price2.price ||
      price1.total !== price2.total
    ) {
      return false;
    }
  }

  return true;
};
