import { shouldSetThereIsData } from "@Pages/CreateServicePage/views/validations/validations";

export const HEADER_NAVIGATION_EDIT_PROPS = (
  navigate,
  isButtonHidden,
  onClick,
  setThereIsData,
  form,
  initForm,
  isEditing,
  screenWidth,
  criticalError
) => {
  return {
    title: screenWidth > 450 ? "Editar servicio" : "Editar",
    previousAction: () => {
      if (criticalError) return navigate("../");
      if (shouldSetThereIsData(form, initForm)) return setThereIsData(true);
      navigate("../");
    },
    previousActionMovement: "",
    buttonProps: {
      textBttn: "Guardar",
      isPrimary: true,
      handleClick: onClick,
      isDisabled: isButtonHidden || isEditing || criticalError,
      isLoading: isEditing,
    },
  };
};
