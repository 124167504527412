import { getServices } from "@Services/createNewServiceServices";

export const getServicesAdapter = async (
  setStateResponse,
  setCriticalError
) => {
  const services = await getServices();
  if (services.isSuccess) {
    return services.data.reverse();
  } else {
    setCriticalError(true);
    setStateResponse({
      message: services.message,
      status: services.status,
    });
    return [];
  }
};
