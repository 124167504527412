import getCategoriesAdapter from "@Adapters/GetCategoriesAdapter/getCategoriesAdapter.adapter";
//@ts-ignore
import { SearchWithCards, SlideNotification } from "@viuti/recursos";
import { useEffect, useState } from "react";

const SearchWithCardsBuilder = ({
  label,
  placeholder,
  dataToFilter,
  updateCategories,
  identificator = "categories",
  handleIntro = (text) => {},
  setCategories = (categories) => {},
  initCards = [],
  hasIntro = true,
  style = { marginTop: "16px", marginBottom: "16px" },
  setCriticalError,
  error = "",
  touched = false,
  handleBlur = () => {},
}) => {
  const [search, setSearch] = useState("");
  const [cards, setCards] = useState([]);
  const [showNotification, setShowNotification] = useState({
    message: "",
    status: 0,
  });
  const textInputProps = {
    label: label,
    required: true,
    placeholder: placeholder,
    value: search,
    handleChange: (e) => {
      setSearch(e.target.value);
    },
  };

  useEffect(() => {
    updateCategories((prev) => ({
      ...prev,
      [identificator]: cards.map((card) => ({
        id: card.id,
        name: card.name,
      })),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  useEffect(() => {
    if (initCards.length === 0) return;
    setCards(initCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ ...style }}>
      <SearchWithCards
        textInputProps={textInputProps}
        dataToFiltered={dataToFilter}
        cards={cards}
        setCards={setCards}
        handleIntro={async (text) => {
          try {
            await handleIntro(text);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const categories = await getCategoriesAdapter(
              setCategories,
              setShowNotification,
              setCriticalError
            );
            const category = categories?.find(
              (category) => category?.name === text
            );
            if (!category) return;
            if (cards.find((card) => card.id === category.id)) {
              setShowNotification({
                message: "Ya existe una categoría con ese nombre",
                status: 400,
              });
              return;
            }
            setCategories((prev) => [...prev, category]);
            setCards((prev) => [...prev, category]);
            setShowNotification({
              message: "Categoría agregada exitosamente",
              status: 200,
            });
          } catch (error) {
            setShowNotification({
              message: "Hubo un error al agregar la categoría.",
              status: 400,
            });
          }
        }}
        hasIntro={hasIntro}
        error={error}
        touched={touched}
        handleBlur={handleBlur}
      />
      <SlideNotification
        state={showNotification}
        clearStatus={() =>
          setShowNotification({
            message: "",
            status: 0,
          })
        }
      />
    </div>
  );
};

export default SearchWithCardsBuilder;
