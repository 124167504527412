import { getServicesAdapter } from "@Adapters/GetServicesAdapter/getServiceAdapter";
import { SHOW_SERVICES_UUID } from "@Models/const/securityAccess";
import { useEffect, useState } from "react";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";

const useGetServices = ({ setToast, setCriticalError }) => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!validateModuleAccess(SHOW_SERVICES_UUID, false))
        return setIsLoading(false);
      const servicesBackend = await getServicesAdapter(
        setToast,
        setCriticalError
      );
      setServices(servicesBackend);
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return {
    services,
    setServices,
    isLoading,
    setIsLoading,
  };
};

export default useGetServices;
