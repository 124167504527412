export const areCategoriesEqual = (obj1, obj2) => {
  const categories1 = obj1.categories || [];
  const categories2 = obj2.categories || [];

  if (categories1.length !== categories2.length) {
    return false;
  }

  for (let i = 0; i < categories1.length; i++) {
    const category1 = categories1[i];
    const category2 = categories2[i];

    if (category1.id !== category2.id || category1.name !== category2.name) {
      return false;
    }
  }

  return true;
};
