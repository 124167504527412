import axios from "axios";
import { Domain, getConfig } from "./api";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const postNewService = async (newService) => {
  const url = `${Domain}/servicios/v1/Services/CreateService`;
  const crear_nuevo_servicio = 12;
  trackUserAction(crear_nuevo_servicio);
  try {
    const response = await axios.post(url, newService, getConfig());
    return {
      isSuccess: true,
      data: response.data.data,
      message: response?.data?.message || "Servicio creado correctamente.",
    };
  } catch (error) {
    const msgError =
      error?.response?.data?.message ||
      "Ocurrió un error al crear el servicio.";
    return { isSuccess: false, data: {}, message: msgError };
  }
};

export const getServices = async (idServicio = null) => {
  let url = `${Domain}/servicios/v1/Services/GetServices`;

  const ingresa_vista_servicios = 11;
  trackUserAction(ingresa_vista_servicios);
  if (idServicio) {
    url = `${url}?idService=${idServicio}`;
  }
  try {
    const response = await axios.get(url, getConfig());
    return {
      isSuccess: true,
      status: response?.status || 200,
      data: response.data.data,
      message: response?.data?.message || "Servicios obtenidos correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      status: error?.response?.status || 400,
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los servicios.",
    };
  }
};

export const getCategories = async () => {
  const url = `${Domain}/servicios/v1/Services/GetCategoriesService`;
  try {
    const response = await axios.get(url, getConfig());
    return {
      isSuccess: true,
      data: response.data.data,
      message: response?.data?.message || "Categorías obtenidas correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      message:
        error.response?.data?.message ||
        "Hubo un error al obtener las categorías.",
    };
  }
};

export const postNewCategory = async (newCategory) => {
  const url = `${Domain}/servicios/v1/Services/CreateCategoryService`;
  try {
    const response = await axios.post(url, newCategory, getConfig());
    return {
      isSuccess: true,
      data: response.data.data,
      message: response?.data?.message || "Categoría creada correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: {},
      message:
        error?.response?.data?.message ||
        "Ocurrió un error al crear la categoría.",
    };
  }
};

export const getEmployees = async () => {
  const url = `${Domain}/empleados/v1/Team/TeamMembers`;
  try {
    const response = await axios.get(url, getConfig());
    return {
      isSuccess: true,
      data: response.data.data,
      message: response?.data?.message || "Empleados obtenidos correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      message:
        error.response?.data?.message ||
        "Hubo un error al obtener los empleados.",
    };
  }
};
export const deleteService = async (idServicio) => {
  const url = `${Domain}/servicios/v1/Services/DeleteService?idService=${idServicio}`;
  const eliminar_servicio = 14;
  trackUserAction(eliminar_servicio);
  try {
    const response = await axios.delete(url, getConfig());
    return {
      isSuccess: true,
      message: response?.data?.message || "Servicio eliminado correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      message:
        error.response?.data?.message ||
        "Ocurrió un error al eliminar el servicio.",
    };
  }
};

export const deleteCategory = async (idCategory) => {
  const url = `${Domain}/servicios/v1/Services/DeleteCategory?idCategory=${idCategory}`;
  try {
    const response = await axios.delete(url, getConfig());
    return {
      isSuccess: true,
      message: response?.data?.message || "Categoría eliminada correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      message:
        error.response?.data?.message ||
        "Ocurrió un error al eliminar la categoría.",
    };
  }
};

export const editService = async (putEdit) => {
  const url = `${Domain}/servicios/v1/Services/EditService`;
  const editar_servicio = 13;
  trackUserAction(editar_servicio);
  try {
    const response = await axios.put(url, putEdit, getConfig());
    return {
      isSuccess: true,
      data: response.data.data,
      message: response?.data?.message || "Servicio editado correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: {},
      message: error?.message || "Ocurrió un error al editar el servicio.",
    };
  }
};

export const getLocales = async () => {
  const url = `${Domain}/lounges/v1/Lounges/Lounges`;
  try {
    const response = await axios.get(url, getConfig());
    return {
      isSuccess: true,
      data: response.data.data,
      message: response?.data?.message || "Locales obtenidos correctamente.",
    };
  } catch (error) {
    return {
      isSuccess: false,
      data: [],
      message:
        error?.response?.data?.message ||
        "Hubo un error al obtener los locales.",
    };
  }
};
