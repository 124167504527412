import { areCategoriesEqual } from "@Pages/EditServicePage/utils/areCategoriesEqual";
import { areEmployeesEqual } from "@Pages/EditServicePage/utils/areEmployeesEqual";
import { areLoungesEqual } from "@Pages/EditServicePage/utils/areLoungesEqual";
import { arePricesEqual } from "@Pages/EditServicePage/utils/arePricesEqual";

export const shouldSetThereIsData = (form, initForm) => {
  const conditions = [
    form.serviceName !== initForm.serviceName,
    form.serviceDescription !== initForm.serviceDescription,
    form.treatmentDescription !== initForm.treatmentDescription,
    form.simoultenous !== initForm.simoultenous,
    form.onlineReservation !== initForm.onlineReservation,
    !areCategoriesEqual(form, initForm),
    !areEmployeesEqual(form, initForm),
    !areLoungesEqual(form, initForm),
    !arePricesEqual(form, initForm),
  ];

  return conditions.some((condition) => condition);
};
