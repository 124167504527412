import { openBox } from "@Models/icons";
//@ts-ignore
import style from "./isNotThereServices.module.css";

const IsNotThereServices = () => {
  return (
    <section className={style.isNotServicesSection}>
      <img src={openBox} alt="open box" />
      <h2>No hay servicios agregados</h2>
      <p>Comienza a vender adicionando los servicios de tu nueva cuenta</p>
    </section>
  );
};

export default IsNotThereServices;
