// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+-PZTfaTdIzvYK8EhI0IXA\\=\\= {\n  margin-top: 24px;\n  overflow: auto;\n  border-top: 1px solid #dad1f4;\n}\n\n.\\+-PZTfaTdIzvYK8EhI0IXA\\=\\= button:first-child {\n  border-top: none;\n}\n\n.Dlx9xGCAz4GKcMqIogBNNw\\=\\= {\n  display: flex;\n  gap: 12px;\n  padding-top: 20px;\n  align-items: center;\n}\n\n._3JhkLI3WVo-ybzQP\\+AA7Rw\\=\\= {\n  margin-top: 3rem;\n  font-size: 1.2rem;\n  font-weight: 800;\n  color: #45348e;\n  text-align: center;\n}\n._2U12OaAhq7ZXWrI7gvbl8A\\=\\= {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding-top: 24px;\n}\n.tgKIfnt6ekf5Nk2ZiY\\+iTg\\=\\= {\n  border-radius: 12px;\n  background: #f0edfe;\n  padding: 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ListOfServicesPage/view/isThereServices/style/isThereService.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".servicesWrapper {\n  margin-top: 24px;\n  overflow: auto;\n  border-top: 1px solid #dad1f4;\n}\n\n.servicesWrapper button:first-child {\n  border-top: none;\n}\n\n.searchContainer {\n  display: flex;\n  gap: 12px;\n  padding-top: 20px;\n  align-items: center;\n}\n\n.serviceNotFound {\n  margin-top: 3rem;\n  font-size: 1.2rem;\n  font-weight: 800;\n  color: #45348e;\n  text-align: center;\n}\n.serviceNotFoundContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  padding-top: 24px;\n}\n.serviceNotFoundIcon {\n  border-radius: 12px;\n  background: #f0edfe;\n  padding: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"servicesWrapper": "+-PZTfaTdIzvYK8EhI0IXA==",
	"searchContainer": "Dlx9xGCAz4GKcMqIogBNNw==",
	"serviceNotFound": "_3JhkLI3WVo-ybzQP+AA7Rw==",
	"serviceNotFoundContainer": "_2U12OaAhq7ZXWrI7gvbl8A==",
	"serviceNotFoundIcon": "tgKIfnt6ekf5Nk2ZiY+iTg=="
};
export default ___CSS_LOADER_EXPORT___;
