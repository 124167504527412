//@ts-ignore
import { FormAddMore } from "@viuti/recursos";
import PriceCard from "@Pages/CreateServicePage/components/priceComponent/PriceCard";
//@ts-ignore
import style from "./FormAddMore.module.css";
import { useEffect, useState } from "react";

const DEFAULT_STATE = {
  name: "",
  price: "",
  igv: "",
  total: "",
  duration: "",
  isFree: false,
};

const FormAddMoreBuilder = ({ updateForm, initForm = null }) => {
  const [uuid, setUuid] = useState(0);
  const [form, setForm] = useState([{ ...DEFAULT_STATE, uuid }]);

  // Eliminar el estado childrens y renderizar directamente
  const renderChildren = () =>
    form.map((item) => (
      <PriceCard
        key={item.uuid}
        uuid={item.uuid}
        setForm={setForm}
        form={form}
        {...item}
      />
    ));

  useEffect(() => {
    if (initForm?.prices?.length > 0) {
      setForm(initForm.prices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateForm((prev) => ({
      ...prev,
      prices: form,
    }));
  }, [form, updateForm]);

  return (
    <div className={style.formaAddMoreContainer}>
      <FormAddMore
        buttonText="Agregar"
        childrens={renderChildren()}
        buttonAction={() => {
          const newUuid = uuid + 1;
          setForm((prev) => [...prev, { ...DEFAULT_STATE, uuid: newUuid }]);
          setUuid(newUuid);
        }}
      />
    </div>
  );
};

export default FormAddMoreBuilder;
