import useSearch from "@Pages/ListOfServicesPage/hook/useSearch";
import {
  SearchWithColoredBorder,
  PaginationWrapper,
  Icon,
  //@ts-ignore
} from "@viuti/recursos";
import ServiceCard from "./components/ServiceCard/ServiceCard";
//@ts-ignore
import style from "./style/isThereService.module.css";
import Filter from "./components/filter/Filter";
import { useState } from "react";
import { filterCategoriesToShow } from "./function/filterCards";
import { ServiceIcon } from "@Models/icons";

const IsThereServices = ({ services, setServices, toast, setToast }) => {
  const searchProps = useSearch(services);
  const [filter, setFilter] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(1);
  const PAGINATION = 10;

  // Filtrar servicios basados en categorías seleccionadas
  const serviceFiltered = services.filter((s) =>
    filter.length === 0
      ? true
      : filterCategoriesToShow(
          s.categories.map((c) => c.nameCategory.toUpperCase()),
          filter.map((c) => c.toUpperCase())
        )
  );

  // Filtrar servicios basados en búsqueda y categorías seleccionadas
  const serviceSearchedAndFiltered = serviceFiltered.filter((s) =>
    searchProps.results.some((result) => result.id === s.idService)
  );

  return (
    <>
      <span className={style.searchContainer}>
        <SearchWithColoredBorder {...searchProps} />
        <Filter setFilter={setFilter} filter={filter} />
      </span>
      <section className={style.servicesWrapper}>
        {serviceSearchedAndFiltered
          .map((service, index) => {
            return (
              <ServiceCard
                key={service.idService}
                title={service.nameService}
                cards={service.categories}
                setServices={setServices}
                idService={service.idService}
                setToast={setToast}
              />
            );
          })
          .slice(
            currentPagination * PAGINATION - PAGINATION,
            currentPagination * PAGINATION
          )}
        {serviceSearchedAndFiltered.length > 10 && (
          <PaginationWrapper
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            optionsPerPage={PAGINATION}
            options={serviceSearchedAndFiltered}
            isFixedToBottom={true}
          />
        )}
        {(searchProps.value !== "" || filter.length > 0) &&
          serviceSearchedAndFiltered.length === 0 && (
            <>
              <div className={style.serviceNotFoundContainer}>
                <div className={style.serviceNotFoundIcon}>
                  <Icon
                    path={ServiceIcon}
                    size={40}
                    color={"#45348e"}
                    alt="Servicio"
                  />
                </div>
              </div>
              <h3 className={style.serviceNotFound}>
                No se han encontrado servicios que coincidan con la búsqueda o
                los términos de filtrado.
              </h3>
            </>
          )}
        {filter.length === 0 && serviceSearchedAndFiltered.length === 0 && (
          <h3 className={style.serviceNotFound}>
            No hay categorías seleccionadas y no se han encontrado servicios.
          </h3>
        )}
      </section>
    </>
  );
};

export default IsThereServices;
