import { editService } from "../../services/createNewServiceServices";

export const editServicesAdapter = async (registroData, id) => {
  // Readapt the objet between the front and back
  const toPut = {
    idService: id,
    nameService: registroData.serviceName,
    categories: registroData.categories.map((category) => ({
      idCategory: parseInt(category.id),
    })),
    description: registroData.serviceDescription,
    treatmentDescription: registroData.treatmentDescription,
    onlineReservation: registroData.onlineReservation,
    prices: registroData.prices.map((price) => ({
      namePrice: price.name,
      free: price.free,
      price: parseFloat(price.price),
      totalPrice: parseFloat(price.total),
      igv: parseFloat(price.igv),
      duration: parseInt(price.duration),
    })),
    employees: registroData.employees.map((employee) => ({
      idEmployee: parseInt(employee.id),
    })),
    simultaneousService: registroData.simoultenous,
    locals: registroData.lounges.map((local) => ({
      idLocal: parseInt(local.lounge),
    })),
  };

  //Admin the back answer
  const response = await editService(toPut);
  return response;
};
