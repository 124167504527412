//@ts-ignore
import { TextInput } from "@viuti/recursos";
import { useRef } from "react";

const InputBuilder = ({
  value,
  setValue = (e) => {},
  setValueFunction = (e, i) => {},
  identificator,
  label,
  placeholder,
  isDisabled = false,
  prefix = "",
  id = "",
  error = "",
  touched = false,
  handleBlur = () => {},
}) => {
  const inputRef = useRef(null);

  const textInputProps = {
    label: label,
    placeholder: placeholder,
    value: value,
    handleChange: (e) => {
      const inputValue = e.target.value;
      if (setValueFunction) {
        setValueFunction(inputValue, identificator);
      }
      if (setValue) {
        setValue((prev) => ({ ...prev, [identificator]: inputValue }));
      }
    },
    disabled: isDisabled,
    prefix,
    id,
    required: true,
    error: error,
    touched: touched,
    handleBlur: handleBlur,
    ref: inputRef,
    type: "text",
    autoComplete: "off",
    spellCheck: false,
  };

  return (
    <article>
      <TextInput {...textInputProps} />
    </article>
  );
};

export default InputBuilder;
