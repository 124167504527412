import { CREATE_SERVICES_UUID } from "@Models/const/securityAccess";
import { ROUTES } from "@Models/routes";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";

interface HeaderNavigationProps {
  title: string;
  previousAction: boolean;
  buttonProps: {
    textBttn: string;
    isPrimary: boolean;
    handleClick: () => void;
  };
}

export const HEADER_NAVIGATION_LIST_PROPS = (
  navigate
): HeaderNavigationProps => {
  return {
    title: "Servicios",
    previousAction: false,
    buttonProps: {
      textBttn: "Nuevo servicio",
      isPrimary: true,
      handleClick: () =>
        validateModuleAccess(CREATE_SERVICES_UUID) &&
        navigate(ROUTES.CREATE_SERVICES),
    },
  };
};
