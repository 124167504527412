//@ts-ignore
import { CommonArrowRightIcon, TrashCanIcon } from "@Models/icons";
//@ts-ignore
import style from "./ServiceCards.module.css";
import { useState } from "react";
//@ts-ignore
import { Icon, validateModuleAccess } from "@viuti/recursos";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@Models/routes";
import { ModalDeleteService } from "@Components/Modals/ModalDeleteService";
import useScreenWidth from "@Pages/ListOfServicesPage/hooks/useScreenWidth/useScreenWidth";
import { MODIFY_SERVICES_UUID } from "@Models/const/securityAccess";

const ServiceCard = ({ title, cards, setServices, idService, setToast }) => {
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const screenWidth = useScreenWidth();

  const navigate = useNavigate();

  const deleteCurrentService = async (e) => {
    e.stopPropagation(); // Detener la propagación del evento click
    setIsDeleting(true);
    setShowModal(true);
  };

  const handleCardClick = (e) => {
    if (!validateModuleAccess(MODIFY_SERVICES_UUID)) return;
    if (!isDeleting) {
      e.stopPropagation(); // Detener la propagación del evento click
      navigate(ROUTES.EDIT_SERVICES + "/" + idService);
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setIsDeleting(false); // Restablecer isDeleting a false al cerrar el modal
  };
  return (
    <button
      className={style.serviceCard}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCardClick}
    >
      <div className={style.containerButtonSelect}>
        <h3>{title.toUpperCase()}</h3>
        <ul>
          {cards.map((card) => {
            return <li key={card.idCategory}>{card.nameCategory}</li>;
          })}
        </ul>
      </div>
      <span>
        {screenWidth > 900 && (
          <button
            style={{ opacity: isHovered ? 1 : 0 }}
            className={style.icon__edit}
          >
            <Icon
              path={CommonArrowRightIcon}
              color="#45348E"
              size={16}
              alt="trash icon"
            />
          </button>
        )}
        <button onClick={deleteCurrentService} className={style.icon__delete}>
          <Icon
            path={TrashCanIcon}
            color="#C36363"
            size={16}
            alt="trash icon"
          />
        </button>
      </span>
      {showModal && (
        <ModalDeleteService
          closeModal={closeModal}
          idService={idService}
          setServices={setServices}
          setToast={setToast}
          screenWidth={screenWidth}
          nameService={title}
        />
      )}
    </button>
  );
};

export default ServiceCard;
