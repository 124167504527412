// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nbuuHQIO-2nmMcK5brgbfw\\=\\= {\n  max-width: 1100px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/builders/FormAddMore/FormAddMore.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB","sourcesContent":[".formaAddMoreContainer {\n  max-width: 1100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formaAddMoreContainer": "nbuuHQIO-2nmMcK5brgbfw=="
};
export default ___CSS_LOADER_EXPORT___;
