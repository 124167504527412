import { getLocales } from "@Services/createNewServiceServices";

export const getLoungesName = async (setState, setCriticalError) => {
  const loungesName = await getLocales();
  if (!loungesName.isSuccess) {
    setCriticalError(true);
    setState({
      message: loungesName.message,
      status: 400,
    });
    return [];
  } else {
    return loungesName.data.map((lounge) => {
      return {
        lounge: lounge.lounge,
        nameLounge: lounge.nameLounge,
      };
    });
  }
};
