import { useEffect, useState } from "react";
import {
  HeaderNavigation,
  AsideSectionMenu,
  SlideNotification,
  ModalUnsavedChanges,
  CriticalErrorPage,
  validateModuleAccess,
  //@ts-ignore
} from "@viuti/recursos";
import { HEADER_NAVIGATION_CREATE_PROPS } from "./consts/HeaderNavegation";
import useForm from "./hook/useForm";
//@ts-ignore
import style from "./style/CreateSection.module.css";
import useInitState from "./hook/useInitState";
import LoadingView from "@Components/loadingView/LoadingView";
import { useNavigate } from "react-router-dom";
import BasicInformation from "./views/BasicInformation";
import PricesAndDuration from "./views/PricesAndDuration";
import EmployeeesAndLounges from "./views/EmployeeesAndLounges";
import { createNewServicesAdapter } from "@Adapters/CreateServicesAdapter/createNewService.adapter";
import { HEADER_NAVIGATION_EDIT_PROPS } from "@Pages/EditServicePage/consts/HeaderNavegation";
import { editServicesAdapter } from "@Adapters/EditServiceAdapter/editService.adapter";
import { ROUTES } from "@Models/routes";
import { areCategoriesEqual } from "@Pages/EditServicePage/utils/areCategoriesEqual";
import { areEmployeesEqual } from "@Pages/EditServicePage/utils/areEmployeesEqual";
import { areLoungesEqual } from "@Pages/EditServicePage/utils/areLoungesEqual";
import { arePricesEqual } from "@Pages/EditServicePage/utils/arePricesEqual";
import useScreenWidth from "@Pages/ListOfServicesPage/hooks/useScreenWidth/useScreenWidth";
import { CREATE_SERVICES_UUID } from "@Models/const/securityAccess";

const CreateService = (initForm = null) => {
  const navigate = useNavigate();
  const { form, setForm } = useForm(initForm);
  const [state, setState] = useState({ message: "", status: 0 });
  const [selectedLounges, setSelectedLounges] = useState([]);
  const [currentView, setCurrentView] = useState(0);
  const [isButtonHidden, setIsButtonHidden] = useState(true);
  const [isButtonHiddenEdit, setIsButtonHiddenEdit] = useState(true);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [criticalError, setCriticalError] = useState(false);
  const { categories, employees, lounges, isLoading, setCategories } =
    useInitState(setState, setCriticalError);
  const screenWidth = useScreenWidth();

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      lounges: selectedLounges,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLounges]);

  useEffect(() => {
    if (!initForm.initForm) {
      !validateModuleAccess(CREATE_SERVICES_UUID, false) && navigate("/");
    }
    setSelectedLounges(initForm?.initForm?.lounges || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      form.serviceName !== "" &&
      form.prices?.length > 0 &&
      form.prices.every(
        (price) =>
          price.name !== "" &&
          price.duration !== "" &&
          price.igv !== "" &&
          price.price !== "" &&
          price.total !== ""
      ) &&
      selectedLounges.length > 0 &&
      form.categories.length > 0 &&
      form.employees.length > 0
    ) {
      setIsButtonHidden(false);
    } else {
      setIsButtonHidden(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const isBasicFormValid =
      form.serviceName !== "" &&
      form.prices?.length > 0 &&
      form.prices.every(
        (price) =>
          price.name !== "" &&
          price.duration !== "" &&
          price.igv !== "" &&
          price.price !== "" &&
          price.total !== ""
      ) &&
      selectedLounges.length > 0 &&
      form.categories.length > 0 &&
      form.employees.length > 0;

    const isFormUnchanged =
      form?.serviceName === initForm?.initForm?.serviceName &&
      form?.serviceDescription === initForm?.initForm?.serviceDescription &&
      form?.treatmentDescription === initForm?.initForm?.treatmentDescription &&
      form?.simoultenous === initForm?.initForm?.simoultenous &&
      form?.onlineReservation == initForm?.initForm?.onlineReservation &&
      areCategoriesEqual(form, initForm.initForm) &&
      areEmployeesEqual(form, initForm.initForm) &&
      areLoungesEqual(form, initForm.initForm) &&
      arePricesEqual(form, initForm.initForm);

    if (isBasicFormValid) {
      setIsButtonHiddenEdit(isFormUnchanged);
    } else {
      setIsButtonHiddenEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onCreateService = async () => {
    setIsCreating(true);
    const serviceResponse = await createNewServicesAdapter(form);
    if (serviceResponse.isSuccess) {
      setTimeout(() => {
        setIsCreating(false);
        navigate("/../" + ROUTES.BASE_ROUTE);
      }, 3000);
      setState({ message: serviceResponse.message, status: 200 });
    } else {
      setIsCreating(false);
      setState({ message: serviceResponse.message, status: 400 });
    }
  };

  const onEditService = async () => {
    setIsEditing(true);
    const response = await editServicesAdapter(form, initForm?.initForm?.id);
    if (response.isSuccess) {
      setTimeout(() => {
        navigate("/../" + ROUTES.BASE_ROUTE);
      }, 1500);
      setState({ message: response.message, status: 200 });
    } else {
      setIsEditing(false);
      setState({
        message: response.message,
        status: 400,
      });
    }
  };

  if (isLoading)
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <LoadingView />
      </div>
    );

  const HEADER_PROPS = initForm?.initForm
    ? HEADER_NAVIGATION_EDIT_PROPS(
        navigate,
        isButtonHiddenEdit,
        onEditService,
        setShowUnsavedChanges,
        form,
        initForm?.initForm,
        isEditing,
        screenWidth,
        criticalError
      )
    : HEADER_NAVIGATION_CREATE_PROPS(
        navigate,
        isButtonHidden,
        onCreateService,
        setShowUnsavedChanges,
        form,
        isCreating,
        screenWidth,
        criticalError
      );

  if (criticalError) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <HeaderNavigation {...HEADER_PROPS} />
        <CriticalErrorPage />
        <SlideNotification
          state={state}
          clearStatus={() => {
            setState({ message: "", status: 0 });
          }}
        />
      </div>
    );
  }

  return (
    <div
      id="viuti-front-mainContent"
      style={{
        overflow: "auto",
      }}
    >
      <HeaderNavigation {...HEADER_PROPS} />
      <div className={style.content}>
        <AsideSectionMenu
          linkArray={[
            {
              name: "Datos básicos",
              href: "#BasicData",
            },
            {
              name: "Precio y duración",
              href: "#PriceAndDuration",
            },
            {
              name: "Equipo y locales",
              href: "#TeamAndLounge",
            },
          ]}
          currentSelected={currentView}
          setCurrentSelected={setCurrentView}
        >
          <section className={style.createSection}>
            <BasicInformation
              form={form}
              categories={categories}
              setForm={setForm}
              setCurrentView={setCurrentView}
              setCategories={setCategories}
              notificationState={setState}
              setCriticalError={setCriticalError}
            />

            <PricesAndDuration
              employees={employees}
              setForm={setForm}
              form={form}
              setCurrentView={setCurrentView}
            />

            <EmployeeesAndLounges
              selectedLounges={selectedLounges}
              setSelectedLounges={setSelectedLounges}
              lounges={lounges}
              setCurrentView={setCurrentView}
            />
          </section>
          <SlideNotification
            state={state}
            clearStatus={() => {
              setState({ message: "", status: 0 });
            }}
          />
          <ModalUnsavedChanges
            changesModalVisible={showUnsavedChanges}
            setChangesModalVisible={setShowUnsavedChanges}
            navigate={navigate}
            navigateTo={"../"}
          />
        </AsideSectionMenu>
      </div>
    </div>
  );
};

export default CreateService;
