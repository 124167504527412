import { shouldSetThereIsData } from "../views/validations/validations";

export const HEADER_NAVIGATION_CREATE_PROPS = (
  navigate,
  isButtonHidden,
  onClick,
  setThereIsData,
  form,
  isCreating,
  screenWidth,
  criticalError
) => {
  const initForm = {
    serviceName: "",
    serviceDescription: "",
    treatmentDescription: "",
    onlineReservation: false,
    categories: [],
    prices: [
      {
        name: "",
        price: "",
        igv: "",
        total: "",
        duration: "",
        isFree: false,
        uuid: 0,
      },
    ],
    employees: [],
    simoultenous: 0,
    lounges: [],
  };

  return {
    title: screenWidth > 450 ? "Nuevo servicio" : "Nuevo",
    previousAction: () => {
      if (criticalError) return navigate("../");
      if (shouldSetThereIsData(form, initForm)) return setThereIsData(true);
      navigate("../");
    },
    previousActionMovement: "",
    buttonProps: {
      textBttn: "Guardar",
      isPrimary: true,
      handleClick: onClick,
      isDisabled: isButtonHidden || isCreating || criticalError,
      isLoading: isCreating,
    },
  };
};
