import { WarningTriangleIcon } from "@Models/icons";
// @ts-ignore
import style from "./ModalDeleteService.module.css";
// @ts-ignore
import { ModalAcceptCancel } from "@viuti/recursos";
import { deleteService } from "@Services/createNewServiceServices";
import { useState } from "react";

export const ModalDeleteService = ({
  closeModal,
  setToast,
  idService,
  nameService,
  setServices,
  screenWidth,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <ModalAcceptCancel
      title="Eliminar servicio"
      showButtons={{
        showButtonClose: screenWidth <= 500,
        showButtonOne: screenWidth > 500,
        showButtonTwo: true,
      }}
      actionButtonClose={() => closeModal()}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: () => closeModal(),
      }}
      buttonTwo={{
        textButtonTwo: "Eliminar",
        actionButtonTwo: async () => {
          setIsDisabled(true);
          const response = await deleteService(idService);
          if (response.isSuccess) {
            setServices((prev) =>
              prev.filter((service) => service.idService !== idService)
            );
            setToast({
              message: response.message,
              status: 200,
            });
            return closeModal();
          }
          setIsDisabled(false);
          setToast({
            message: response.message,
            status: 400,
          });
        },
        isLoading: isDisabled,
        isDisabled: isDisabled,
      }}
    >
      <div className={style.body}>
        <div className={style.containerForm}>
          <figure>
            <img src={WarningTriangleIcon} alt="Simbolo de advertencia" />
          </figure>
          <p className={style.message}>
            ¿Estás seguro de que deseas eliminar este servicio?
            <br />
            Ten en cuenta que tal acción no se puede deshacer.
            <strong className={style.strong}>{nameService}</strong>
          </p>
        </div>
      </div>
    </ModalAcceptCancel>
  );
};
