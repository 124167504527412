import { postNewCategory } from "@Services/createNewServiceServices";

export const createNewServiceCategoryAdapter = async (
  category: any,
  setError
) => {
  const newCategory = await postNewCategory({ nameCategory: category });
  if (newCategory.isSuccess) {
    const { data } = newCategory;
    const formatedCategory = {
      id: data.idNewCategory,
      name: data.nameNewCategory,
    };
    return formatedCategory;
  }
  setError({ message: newCategory.message, status: 400 });
};
