import FormAddMoreBuilder from "@Components/builders/FormAddMore/FormAddMoreBuilder";
import SearchWithCardsBuilder from "@Components/builders/SearchWithCardsBuilder/SearchWithCardsBuilder";
import CheckBuilder from "@Components/builders/checkBuilder/CheckBuilder";
import {
  InputAdditionSubtract,
  //@ts-ignore
} from "@viuti/recursos";
//@ts-ignore
import style from "../style/CreateSection.module.css";
import { useEffect, useState } from "react";

const PricesAndDuration = ({ employees, setForm, form, setCurrentView }) => {
  const [errors, setErrors] = useState({
    employeesError: "",
  });
  const handleEmployeesBlur = () => {
    if (form.employees.length === 0) {
      setErrors((prev) => ({
        ...prev,
        employeesError: "El empleado es requerido",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        employeesError: "",
      }));
    }
  };

  useEffect(() => {
    if (form.employees.length !== 0) {
      setErrors((prev) => ({
        ...prev,
        employeesError: "",
      }));
    }
  }, [form.employees]);

  return (
    <div>
      {/* <h3>Reservas en línea</h3>
      <CheckBuilder
        label="Disponible para reservas online"
        setForm={setForm}
        check={form.onlineReservation}
      /> */}
      <h3>Precio y duración</h3>
      <FormAddMoreBuilder updateForm={setForm} initForm={form} />
      <h3 id="TeamAndLounge" style={{ marginBottom: "0px" }}>
        Equipo
      </h3>
      <SearchWithCardsBuilder
        label="Empleados"
        placeholder="buscar/agregar un empleado"
        dataToFilter={employees}
        updateCategories={setForm}
        identificator="employees"
        initCards={form.employees}
        hasIntro={false}
        setCriticalError={() => {}}
        error={errors.employeesError}
        touched={!!errors.employeesError}
        handleBlur={handleEmployeesBlur}
      />
      <h3>Servicios en simultáneo</h3>
      <div className={style.servicesDoing}>
        <p>Permite</p>
        <InputAdditionSubtract
          min={0}
          value={form.simoultenous}
          setValue={(value) => {
            setForm((prev) => ({ ...prev, simoultenous: value }));
          }}
        />
        <p>servicios en simultáneo</p>
      </div>
    </div>
  );
};

export default PricesAndDuration;
