import {
  deleteCategory,
  getCategories,
} from "@Services/createNewServiceServices";

const getCategoriesAdapter = async (
  setCategories,
  setShowNotification,
  setCriticalError
) => {
  const categories = await getCategories();
  if (!categories.isSuccess) {
    setCriticalError && setCriticalError(true);
    return setShowNotification({
      message: categories.message,
      status: 400,
    });
  }

  return categories.data.map((category) => {
    return {
      name: category.nameCategory,
      id: category.idCategory,
      actions: [
        {
          name: "Eliminar",
          action: () => {
            setShowNotification({
              message: "Eliminando categoría...",
              status: "pending",
            });
            deleteCategory(category.idCategory).then((res) => {
              if (!res.isSuccess)
                return setShowNotification({
                  message: res.message,
                  status: 400,
                });
              setCategories((prev) =>
                prev.filter((cat) => cat.id !== category.idCategory)
              );
              setShowNotification({
                message: res.message,
                status: 200,
              });
            });
          },
        },
      ],
    };
  });
};

export default getCategoriesAdapter;
