import { getCategories } from "@Services/createNewServiceServices";

export const getCategoryFilterAdapter = async (setToast, errorMessage) => {
  const categories = await getCategories();
  if (!categories.isSuccess) {
    setToast({
      message: errorMessage,
      status: 400,
    });
    return [];
  }
  const formatedCategories = categories?.data?.map((category) => ({
    id: category.idCategory,
    name: category.nameCategory,
  }));

  const formatedCategoriesAndDefault = [...formatedCategories];

  return formatedCategoriesAndDefault;
};
