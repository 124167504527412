export const filterCategoriesToShow = (
  categories: string[],
  allowCategories: string[]
): boolean => {
  const categoryArr = categories.map((category) =>
    allowCategories.includes(category)
  );

  return categoryArr.includes(true);
};
