import { useEffect, useState } from "react";

const useSearch = (services) => {
  const [search, setSearch] = useState("");
  const [dataToSearch, setDataToSearch] = useState([]);
  const [results, setResults] = useState([]);

  const serviceAdapted = services.map((service) => ({
    id: service.idService,
    name: service.nameService,
  }));

  useEffect(() => {
    const getDataToSearch = async () => {
      setDataToSearch(serviceAdapted);
      setResults(serviceAdapted);
    };
    getDataToSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When the user type on the search
  useEffect(() => {
    // 1. If the search is empty, show all the services
    if (search === "") return setResults(serviceAdapted);

    // 2. Filter the services
    const filteredServices = dataToSearch.filter((service) =>
      service.name.toUpperCase().includes(search.toUpperCase())
    );
    // 3. Save the results
    setResults(filteredServices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    value: search,
    setValue: setSearch,
    showResults: false,
    placeholder: "Buscar servicio",
    results,
    clearInput: false,
  };
};

export default useSearch;
