import {
  GETSERVICE,
  GETCATEGORY,
  PUTSERVICE,
  DELETE_SERVICE,
  EDIT_CATEGORY,
  POSTNEWSERVICE,
  SELECTEDCATEGORY,
  UPDATE_NOTIFICATION_MESSAGE,
  UPDATE_NOTIFICATION_LOADING,
  UPDATE_NOTIFICATION_STATUS,
  CLEAR_NOTIFICATION,
} from "../actions/const";

const initialState = {
  services: [],
  categories: [],
  employees: [],
  selectedCategory: null,
  notification: {
    message: "",
    status: 0,
    loading: false,
  },
};

export default function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GETSERVICE:
      return {
        ...state,
        services: payload,
      };

    case PUTSERVICE:
      return {
        ...state,
        putservice: payload,
      };

    case DELETE_SERVICE:
      const filterService = state.services.filter(
        (service) => service.idService !== payload
      );
      return {
        ...state,
        services: filterService,
      };

    case GETCATEGORY:
      return {
        ...state,
        categories: payload,
      };
    case EDIT_CATEGORY:
      return {
        state,
        categories: payload,
      };

    case SELECTEDCATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    // Other cases for different actions
    case POSTNEWSERVICE:
      return {
        ...state,
        services: [...state.services, payload],
      };
    case UPDATE_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notification: { ...state.notification, message: payload },
      };
    case UPDATE_NOTIFICATION_STATUS:
      return {
        ...state,
        notification: { ...state.notification, status: payload },
      };
    case UPDATE_NOTIFICATION_LOADING:
      return {
        ...state,
        notification: { ...state.notification, loading: payload },
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: "",
          status: 0,
          loading: false,
        },
      };
    default:
      return state;
  }
}
