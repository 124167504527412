import React from "react";
import {
  HeaderNavigation,
  SlideNotification,
  CriticalErrorPage,
  UnauthorizedMessage,
  validateModuleAccess,
  //@ts-ignore
} from "@viuti/recursos";
import { HEADER_NAVIGATION_LIST_PROPS } from "./consts/HeaderNavegation";
import { useNavigate } from "react-router-dom";
import useGetServices from "./hook/useGetServices";
import LoadingView from "@Components/loadingView/LoadingView";
import IsNotThereServices from "./view/isNotThereServices/IsNotThereServices";
import IsThereServices from "./view/isThereServices/IsThereServices";
import useScreenWidth from "./hooks/useScreenWidth/useScreenWidth";
// @ts-ignore
import style from "./ListServicesPage.module.css";
import { SHOW_SERVICES_UUID } from "@Models/const/securityAccess";

const ListServicePage = () => {
  const navigate = useNavigate();
  const [toast, setToast] = React.useState({
    message: "",
    status: 0,
  });
  const [criticalError, setCriticalError] = React.useState(false);
  const { services, setServices, isLoading } = useGetServices({
    setToast,
    setCriticalError,
  });

  const screenWidth = useScreenWidth();
  const isThereServices = services.length > 0;

  if (isLoading)
    return (
      <div id="viuti-front-mainContent">
        <LoadingView />
      </div>
    );

  if (criticalError) {
    return (
      <div id="viuti-front-mainContent">
        <HeaderNavigation {...HEADER_NAVIGATION_LIST_PROPS(navigate)} />
        <CriticalErrorPage />
        <SlideNotification
          state={toast}
          clearStatus={() => {
            setToast({ message: "", status: 0 });
          }}
        />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation {...HEADER_NAVIGATION_LIST_PROPS(navigate)} />
      {!validateModuleAccess(SHOW_SERVICES_UUID, false) ? (
        <div className={style.unauthorized}>
          <UnauthorizedMessage
            restrictedFeatureName={"Listado de servicios"}
            restrictedUUID={SHOW_SERVICES_UUID}
          />
        </div>
      ) : !isThereServices ? (
        <IsNotThereServices />
      ) : (
        <IsThereServices
          toast={toast}
          setToast={setToast}
          services={services}
          setServices={setServices}
        />
      )}
      <SlideNotification
        state={toast}
        clearStatus={() => {
          setToast({ message: "", status: 0 });
        }}
      />
    </div>
  );
};

export default ListServicePage;
