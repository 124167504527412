import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store/store";
import { ROUTES } from "@Models/routes";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";
import CreateService from "./CreateServicePage/CreateService";
import EditServicePage from "./EditServicePage/EditServicePage";
import ListServicePage from "./ListOfServicesPage/ListServicesPage";

// Route page of the application
export default function App() {
  return (
    <Provider store={store}>
      <Router basename={ROUTES.BASE_ROUTE}>
        <Routes>
          <Route
            path={ROUTES.CREATE_SERVICES}
            element={<CreateService />}
          ></Route>
          <Route
            path={ROUTES.EDIT_SERVICES + "/:id"}
            element={<EditServicePage />}
          ></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
          <Route path="" element={<ListServicePage />}></Route>
        </Routes>
      </Router>
    </Provider>
  );
}
