import { createNewServiceCategoryAdapter } from "@Adapters/CreateServiceCategoryAdapter/createNewServiceCategory.adapter";
import InputAreaBuilder from "@Components/builders/InputAreaBuilder/InputAreaBuilder";
import InputBuilder from "@Components/builders/InputBuilder/InputBuilder";
import SearchWithCardsBuilder from "@Components/builders/SearchWithCardsBuilder/SearchWithCardsBuilder";
//@ts-ignore
import { SlideNotification } from "@viuti/recursos";
import { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import getCategoriesAdapter from "../../../adapters/GetCategoriesAdapter/getCategoriesAdapter.adapter";
const BasicInformation = ({
  form,
  categories,
  setForm,
  setCurrentView,
  setCategories,
  notificationState,
  setCriticalError,
}) => {
  const [state, setState] = useState({ message: "", status: 0 });
  const onEnter = () => setCurrentView(0);
  const onLeave = () => setCurrentView(1);
  const [errors, setErrors] = useState({
    serviceNameError: "",
    categoryError: "",
  });

  const handleServiceNameBlur = () => {
    if (form.serviceName === "") {
      setErrors((prev) => ({
        ...prev,
        serviceNameError: "El nombre del servicio es requerido",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        serviceNameError: "",
      }));
    }
  };

  const handleCategoriesBlur = () => {
    if (form.categories.length === 0) {
      setErrors((prev) => ({
        ...prev,
        categoryError: "La categoría del servicio es requerida",
      }));
    }
  };

  useEffect(() => {
    if (form.serviceName !== "") {
      setErrors((prev) => ({
        ...prev,
        serviceNameError: "",
      }));
    }
  }, [form.serviceName]);

  useEffect(() => {
    if (form.categories.length !== 0) {
      setErrors((prev) => ({
        ...prev,
        categoryError: "",
      }));
    }
  }, [form.categories]);

  return (
    <div>
      <InputBuilder
        value={form.serviceName}
        setValue={setForm}
        identificator={"serviceName"}
        label={"Nombre del servicio"}
        placeholder={"Servicio"}
        id="BasicData"
        error={errors.serviceNameError}
        touched={!!errors.serviceNameError}
        handleBlur={handleServiceNameBlur}
      />
      {/* @ts-ignore */}
      <Waypoint onEnter={onEnter} onLeave={onLeave} />
      <SearchWithCardsBuilder
        label="Categoría del servicio"
        placeholder="buscar/agregar una categoría"
        dataToFilter={categories}
        updateCategories={setForm}
        handleIntro={async (text) => {
          notificationState({
            message: "Creando categoría...",
            status: "pending",
            toastTimeDuration: "INFINITE",
          });
          await createNewServiceCategoryAdapter(text, setState);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const categories = await getCategoriesAdapter(
            setCategories,
            setState,
            setCriticalError
          );
          notificationState({
            message: "",
            status: 0,
            toastTimeDuration: "INFINITE",
          });
          setCategories(categories);
        }}
        initCards={form.categories}
        setCriticalError={setCriticalError}
        error={errors.categoryError}
        touched={!!errors.categoryError}
        handleBlur={handleCategoriesBlur}
      />
      <InputAreaBuilder
        value={form.serviceDescription}
        setValue={setForm}
        identificator={"serviceDescription"}
        label={"Descripción del servicio"}
        placeholder={"Descripción"}
        style={{ marginBottom: "16px" }}
      />
      <span id="PriceAndDuration"></span>
      <SlideNotification
        state={state}
        clearStatus={() => {
          setState({ message: "", status: 0 });
        }}
      />
    </div>
  );
};

export default BasicInformation;
