//@ts-ignore
import { TextAreaInput } from "@viuti/recursos";

const InputAreaBuilder = ({
  value,
  setValue,
  identificator,
  label,
  placeholder,
  id = "",
  style = {},
}) => {
  const textInputProps = {
    label: label,
    placeholder: placeholder,
    value: value,
    handleChange: (e) => {
      setValue((prev) => ({ ...prev, [identificator]: e }));
    },
    id,
  };

  return (
    <div style={style}>
      <TextAreaInput {...textInputProps} />
    </div>
  );
};

export default InputAreaBuilder;
