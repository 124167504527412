export const areLoungesEqual = (obj1, obj2) => {
  const lounges1 = obj1.lounges || [];
  const lounges2 = obj2.lounges || [];

  if (lounges1.length !== lounges2.length) {
    return false;
  }

  for (let i = 0; i < lounges1.length; i++) {
    const lounge1 = lounges1[i];
    const lounge2 = lounges2[i];

    if (lounge1.lounge !== lounge2.lounge) {
      return false;
    }

    if (lounge1.nameLounge !== lounge2.loungeName) {
      return false;
    }
  }

  return true;
};
