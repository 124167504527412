export const GETSERVICE = "GETSERVICE";
export const POSTNEWSERVICE = "POSTNEWSERVICE";
export const PUTSERVICE = "PUTSERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GETCATEGORY = "GETCATEGORY";
export const SELECTEDCATEGORY = "SELECTEDCATEGORY";
export const UPDATE_NOTIFICATION_MESSAGE = "UPDATE_NOTIFICATION_MESSAGE";
export const UPDATE_NOTIFICATION_LOADING = "UPDATE_NOTIFICATION_LOADING";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const UPDATE_NOTIFICATION_STATUS = "UPDATE_NOTIFICATION_STATUS";
