import axios from "axios";

const UNAUTHORIZED_URL = window.location.origin + "/";
const SERVER_FAIL_URL = window.location.origin + "/time-out";
const TIMEOUT = 30000;

axios.interceptors.request.use((config) => {
  // Add the timeout to the request config, to cancel the request if it takes too long
  config.timeout = TIMEOUT;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  (error) => {
    // If the request fails, we check the status code of the request and act accordingly
    const status = error.response ? error.response.status : null;

    // If the error status is 401, i.e. Unauthorized, we redirect the user to the login page
    if (status === 401) {
      localStorage.clear();
      window.location.href = UNAUTHORIZED_URL;

      // If the error status is 500, i.e. Server Error,
      // we redirect the user to the server error page
    } else if (status >= 500 && status < 600) {
      window.location.href = SERVER_FAIL_URL;
    } else {
      // If the error is due to any other reason,
      //we just return the error and the application will handle it to resolve
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
