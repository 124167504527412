import CheckBuilder from "@Components/builders/checkBuilder/CheckBuilder";
//@ts-ignore
import style from "../style/CreateSection.module.css";
import { Waypoint } from "react-waypoint";

const EmployeeesAndLounges = ({
  setSelectedLounges,
  selectedLounges,
  lounges,
  setCurrentView,
}) => {
  return (
    <>
      <h3>Locales</h3>
      <p className={style.lounges}>
        Elige los locales en los que estará disponible este servicio
        <span className={style.inputRequired}>*</span>
      </p>
      <CheckBuilder
        label="Todos los locales"
        check={selectedLounges.length === lounges.length}
        handleCheck={(check) => {
          if (check) {
            setSelectedLounges(lounges);
          } else {
            setSelectedLounges([]);
          }
        }}
      />
      <section className={style.sectionContainer}>
        {lounges.map((l: any) => {
          const isChecked = selectedLounges.find(
            (item) => item.lounge === l.lounge
          );
          return (
            <CheckBuilder
              key={l.lounge}
              check={isChecked}
              label={l.nameLounge}
              handleCheck={(check) => {
                if (check) {
                  if (isChecked) return;
                  setSelectedLounges((prev) => [...prev, l]);
                } else {
                  setSelectedLounges((prev) =>
                    prev.filter((item) => item.lounge !== l.lounge)
                  );
                }
              }}
            />
          );
        })}
      </section>
      {/* @ts-ignore */}
      <Waypoint
        onEnter={() => {
          setCurrentView(2);
        }}
        onLeave={() => {
          setCurrentView(1);
        }}
      />
    </>
  );
};

export default EmployeeesAndLounges;
