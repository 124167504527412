import { FilterIcon } from "@Models/icons";
//@ts-ignore
import style from "./Filter.module.css";
import {
  CheckboxInput,
  SearchWithColoredBorder,
  ModalAcceptCancel,
  SlideNotification,
  //@ts-ignore
} from "@viuti/recursos";
import { useEffect, useRef, useState } from "react";
import { getCategoryFilterAdapter } from "@Adapters/GetCategoriesAdapter/getCategoryFilterAdapter.adapter";

const Filter = ({ setFilter, filter }) => {
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const modalRef = useRef();
  const [toast, setToast] = useState({
    message: "",
    status: 0,
  });

  useEffect(() => {
    const errorMessage =
      "Hubo un error al obtener las categorías para aplicar los filtros.";
    getCategoryFilterAdapter(setToast, errorMessage).then((res) => {
      setCategories(res);
      setFilter(res.map((category) => category.name));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      //@ts-ignore
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const closeFilter = () => {
    setShowFilter(false);
  };

  const handleSearchInput = (event: React.KeyboardEvent) => {
    let charCode = event.keyCode;
    if (charCode >= 65 && charCode <= 90) setSearch(search + event.key);
    if (charCode === 8) setSearch(search.slice(0, -1));
  };

  return (
    <section className={style.filterSection} ref={modalRef}>
      <button onClick={() => setShowFilter(!showFilter)}>
        <img src={FilterIcon} alt="filter icon" />
      </button>
      {showFilter && (
        <ModalAcceptCancel
          title="Filtros"
          visibility={showFilter}
          actionButtonClose={closeFilter}
          showButtons={{
            showButtonOne: false,
            showButtonTwo: false,
            showButtonClose: true,
          }}
          buttonOne={{
            textButtonOne: "Aplicar",
            actionButtonOne: closeFilter,
          }}
          buttonTwo={{
            textButtonTwo: "Cancelar",
            actionButtonTwo: closeFilter,
          }}
        >
          <article className={style.resultContainer}>
            <SearchWithColoredBorder
              placeholder={"Buscar categoría"}
              value={search}
              setValue={() => {}}
              onKeyDown={handleSearchInput}
            />
            <br />
            <section className={style.resultContainer__items}>
              {search === "" && (
                <CheckboxInput
                  label="SELECCIONAR TODAS"
                  inputProps={{
                    checked: filter.length === categories.length,
                    onChange: () => {
                      if (filter.length !== categories.length) {
                        setFilter(categories.map((category) => category.name));
                      } else {
                        setFilter([]);
                      }
                    },
                  }}
                />
              )}
              {categories.map((category, key) => {
                if (
                  search !== null &&
                  search !== "" &&
                  !category.name.toUpperCase().includes(search.toUpperCase())
                )
                  return;
                return (
                  <CheckboxInput
                    key={key}
                    label={category.name.toUpperCase()}
                    inputProps={{
                      checked: filter.includes(category.name),
                      onChange: () => {
                        if (!filter.includes(category.name)) {
                          setFilter((prev) => [...prev, category.name]);
                        } else {
                          setFilter(filter.filter((c) => c !== category.name));
                        }
                      },
                    }}
                  />
                );
              })}
            </section>
          </article>
        </ModalAcceptCancel>
      )}
      <SlideNotification
        state={toast}
        clearStatus={() => {
          setToast({ message: "", status: 0 });
        }}
      />
    </section>
  );
};

export default Filter;
