import { useEffect, useState } from "react";

const useForm = ({ initForm }) => {
  const [form, setForm] = useState({
    serviceName: "",
    serviceDescription: "",
    treatmentDescription: "",
    onlineReservation: false,
    categories: [],
    prices: [],
    employees: [],
    simoultenous: 0,
    lounges: [],
  });
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (initForm) {
      setForm({
        serviceName: initForm.serviceName,
        serviceDescription: initForm.serviceDescription,
        treatmentDescription: initForm.treatmentDescription,
        onlineReservation: initForm.onlineReservation,
        categories: initForm.categories,
        prices: initForm.prices,
        employees: initForm.employees,
        simoultenous: initForm.simoultenous,
        lounges: initForm.lounges,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initForm) {
      if (
        form.serviceName !== initForm.serviceName ||
        form.serviceDescription !== initForm.serviceDescription ||
        form.treatmentDescription !== initForm.treatmentDescription ||
        form.onlineReservation !== initForm.onlineReservation ||
        form.simoultenous !== initForm.simoultenous
      ) {
        setIsEdited(true);
      } else setIsEdited(false);
    }
    if (form.serviceName !== "") setIsEdited(true);
    if (form.serviceDescription !== "") setIsEdited(true);
    if (form.treatmentDescription !== "") setIsEdited(true);
    if (form.categories.length > 0) setIsEdited(true);
    if (form.employees.length > 0) setIsEdited(true);
    if (form.onlineReservation) setIsEdited(true);
    if (form.simoultenous !== 0) setIsEdited(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return {
    form,
    setForm,
    isEdited,
  };
};

export default useForm;
