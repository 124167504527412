import CreateService from "@Pages/CreateServicePage/CreateService";
import { useEffect, useState } from "react";
import useGetServiceById from "./hook/useGetServiceById";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@Models/routes";
import LoadingView from "@Components/loadingView/LoadingView";
import { MODIFY_SERVICES_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";

const EditServicePage = () => {
  const props = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState(null);

  useEffect(() => {
    if (!validateModuleAccess(MODIFY_SERVICES_UUID, false)) {
      return navigate("/");
    }

    (async () => {
      /* eslint-disable react-hooks/rules-of-hooks */
      const service = await useGetServiceById(props.id);
      if (!service.isSuccess) return navigate(ROUTES.BASE_ROUTE);
      setService(service.data[0]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!service)
    return (
      <div id="viuti-front-mainContent">
        <LoadingView />
      </div>
    );

  return (
    <CreateService
      isEditSection={true}
      initForm={{
        id: service.idService,
        serviceName: service.nameService,
        serviceDescription: service.description,
        treatmentDescription: service.descriptionTreatment,
        onlineReservation: service.onlineReservation,
        categories: service.categories.map((category) => ({
          id: category.idCategory,
          name: category.nameCategory,
        })),
        prices: service.prices.map((price) => ({
          id: price.idPrice,
          name: price.namePrice,
          duration: price.duration + " min",
          igv: price.igv.toFixed(2),
          price: price.price.toFixed(2),
          total: price.totalPrice.toFixed(2),
          uuid: price.idPrice,
        })),
        employees: service.employees.map((employee) => ({
          id: employee.idEmployee,
          name: employee.nameEmployee + " " + employee.lastnameEmployee,
        })),
        simoultenous: service.simultaneousAttention,
        lounges: service.locals.map((local) => {
          return {
            lounge: local.idLocal,
            loungeName: local.nameLocal,
          };
        }),
      }}
    />
  );
};

export default EditServicePage;
