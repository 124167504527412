import InputBuilder from "@Components/builders/InputBuilder/InputBuilder";
//@ts-ignore
import style from "./PriceCard.module.css";
import { TrashCanIcon } from "@Models/icons";
//@ts-ignore
import { Icon, TextInput, TimeInput } from "@viuti/recursos";
import { useEffect, useState } from "react";

const PriceCard = ({
  name = "",
  price = "",
  duration = "",
  igv = "",
  total = "",
  isFree = false,
  uuid,
  setForm,
  form,
}) => {
  const [errors, setErrors] = useState({
    nameError: "",
    priceError: "",
    durationError: "",
    priceTotalError: "",
  });

  const handleNameBlur = () => {
    if (name === "") {
      setErrors((prev) => ({
        ...prev,
        nameError: "El nombre del precio es requerido",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        nameError: "",
      }));
    }
  };

  const handlePriceBlur = () => {
    if (price === "") {
      setErrors((prev) => ({
        ...prev,
        priceError: "El precio es requerido",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        priceError: "",
      }));
    }
  };

  const handleDurationBlur = () => {
    if (!duration || duration === "0") {
      setErrors((prev) => ({
        ...prev,
        durationError: "La duración es requerida y debe ser mayor a 0 minutos",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        durationError: "",
      }));
    }
  };

  const handlePriceTotal = () => {
    if (total === "") {
      setErrors((prev) => ({
        ...prev,
        priceTotalError: "El precio total es requerido",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        priceTotalError: "",
      }));
    }
  };

  const autoDelete = () => {
    if (form.length === 1) return;
    setForm((prev) => {
      const newForm = prev.filter((item) => {
        if (item.uuid === uuid) {
          return false;
        }
        return true;
      });
      return newForm;
    });
  };

  const handleChangeValue = (text, identificator) => {
    setForm((prev) => {
      const newForm = prev.map((item) => {
        if (item.uuid === uuid) {
          return {
            ...item,
            [identificator]: text,
          };
        }
        return item;
      });
      return newForm;
    });
  };

  const handleTimeChange = (newTime) => {
    // Convertir el formato HH:mm a minutos totales
    const [hours, minutes] = newTime.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    handleChangeValue(totalMinutes.toString(), "duration");
  };

  const formatDurationToTime = (durationInMinutes) => {
    durationInMinutes = String(durationInMinutes).replace(" min", "");
    if (!durationInMinutes) return "0:00";
    const hours = Math.floor(Number(durationInMinutes) / 60);
    const minutes = Number(durationInMinutes) % 60;
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (name !== "") {
      setErrors((prev) => ({
        ...prev,
        nameError: "",
      }));
    }
  }, [name]);

  useEffect(() => {
    if (price !== "") {
      setErrors((prev) => ({
        ...prev,
        priceError: "",
      }));
    }
  }, [price]);

  useEffect(() => {
    if (duration !== "") {
      setErrors((prev) => ({
        ...prev,
        durationError: "",
      }));
    }
  }, [duration]);

  useEffect(() => {
    if (total !== "") {
      setErrors((prev) => ({
        ...prev,
        priceTotalError: "",
      }));
    }
  }, [total]);

  return (
    <div className={style.priceCard}>
      <InputBuilder
        value={name}
        setValueFunction={(text, identificator) => {
          handleChangeValue(text, identificator);
        }}
        identificator={"name"}
        label={"Nombre de precio"}
        placeholder={"Servicio"}
        error={errors.nameError}
        touched={!!errors.nameError}
        handleBlur={handleNameBlur}
      />
      <TimeInput
        label="Duración"
        value={formatDurationToTime(duration)}
        minuteStep={15}
        onChange={handleTimeChange}
        inputProps={{
          required: true,
          onBlur: handleDurationBlur,
          disabled: false,
        }}
        error={errors.durationError}
        touched={!!errors.durationError}
      />
      <InputBuilder
        value={price}
        setValueFunction={(text, identificator) => {
          handleChangeValue(text, identificator);
          const igv = (text * 0.18).toFixed(2);
          const total = (text * 1.18).toFixed(2);
          handleChangeValue(igv, "igv");
          handleChangeValue(total, "total");
        }}
        identificator={"price"}
        label={"Precio"}
        placeholder={""}
        prefix={"S/."}
        error={errors.priceError}
        touched={!!errors.priceError}
        handleBlur={handlePriceBlur}
      />
      <InputBuilder
        value={igv}
        setValueFunction={(text, identificator) => {
          handleChangeValue(text, identificator);
        }}
        label={"IGV"}
        identificator={"igv"}
        placeholder={""}
        isDisabled={true}
        prefix={"S/."}
      />
      <InputBuilder
        value={total}
        setValueFunction={(text, identificator) => {
          handleChangeValue(text, identificator);
          const price: any = (text / 1.18).toFixed(2);
          const igv = (price * 0.18).toFixed(2);
          handleChangeValue(igv, "igv");
          handleChangeValue(price, "price");
        }}
        identificator={"total"}
        label={"Precio total"}
        placeholder={""}
        prefix={"S/."}
        error={errors.priceTotalError}
        touched={!!errors.priceTotalError}
        handleBlur={handlePriceTotal}
      />
      <button className={style.trashIcon} onClick={() => autoDelete()}>
        <Icon
          path={TrashCanIcon}
          color={form.length === 1 ? "#9f9f9f" : "#C36363"}
          size={16}
        />
      </button>
    </div>
  );
};

export default PriceCard;
