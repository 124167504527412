// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rs0R6N6oY34qBAdqs5C31Q\\=\\= {\n  width: 100%;\n  padding: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 24px;\n  padding-top: 20px;\n\n  & h2 {\n    color: #45348e;\n    font-family: Mulish;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    letter-spacing: 0.233px;\n  }\n\n  & p {\n    color: #45348e;\n    text-align: center;\n    font-family: Mulish;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    letter-spacing: 0.233px;\n    max-width: 500px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ListOfServicesPage/view/isNotThereServices/isNotThereServices.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,iBAAiB;;EAEjB;IACE,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;EAClB;AACF","sourcesContent":[".isNotServicesSection {\n  width: 100%;\n  padding: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 24px;\n  padding-top: 20px;\n\n  & h2 {\n    color: #45348e;\n    font-family: Mulish;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    letter-spacing: 0.233px;\n  }\n\n  & p {\n    color: #45348e;\n    text-align: center;\n    font-family: Mulish;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    letter-spacing: 0.233px;\n    max-width: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isNotServicesSection": "Rs0R6N6oY34qBAdqs5C31Q=="
};
export default ___CSS_LOADER_EXPORT___;
