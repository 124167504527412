import { Loader } from "@Models/icons";
//@ts-ignore
import style from "./loading.module.css";

const LoadingView = () => {
  return (
    <section className={style.loadingSection}>
      <img src={Loader} alt="loader" />
    </section>
  );
};

export default LoadingView;
