// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DB1ogN3GlYSslu0\\+V2B4ow\\=\\= {\n}\n\n.TjVzwGJOf2gUYi0gS6IXwQ\\=\\= {\n  width: 100%;\n}\n\n.Xw65PnKRt0oewnGQsIY2Pw\\=\\= {\n}\n\n._6i98tJH55TWmJmhoCsg7kA\\=\\= {\n}\n\n@keyframes mERrHGrQbssuE56VMrffrw\\=\\= {\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ListOfServicesPage/view/isThereServices/components/filter/Filter.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,WAAW;AACb;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".filterSection {\n}\n\n.resultContainer {\n  width: 100%;\n}\n\n.resultContainer__items {\n}\n\n.result__hidden {\n}\n\n@keyframes slide-in-top {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterSection": "DB1ogN3GlYSslu0+V2B4ow==",
	"resultContainer": "TjVzwGJOf2gUYi0gS6IXwQ==",
	"resultContainer__items": "Xw65PnKRt0oewnGQsIY2Pw==",
	"result__hidden": "_6i98tJH55TWmJmhoCsg7kA==",
	"slide-in-top": "mERrHGrQbssuE56VMrffrw=="
};
export default ___CSS_LOADER_EXPORT___;
