import { useEffect, useState } from "react";
import { getLoungesName } from "@Adapters/loungesName/GetLoungesName";
import { getEmployeesAdapter } from "@Adapters/GetEmployeesAdapter/getEmployees.adapter";
import getCategoriesAdapter from "@Adapters/GetCategoriesAdapter/getCategoriesAdapter.adapter";

const useInitState = (setState, setCriticalError) => {
  const [categories, setCategories] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [lounges, setLounges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesData, employeesData, loungesData] = await Promise.all([
          getCategoriesAdapter(setCategories, setState, setCriticalError),
          getEmployeesAdapter(setState, setCriticalError),
          getLoungesName(setState, setCriticalError),
        ]);
        setCategories(categoriesData);
        setEmployees(employeesData);
        setLounges(loungesData);
      } catch (error) {
        setState({
          message: "Hubo un error al obtener los datos.",
          status: 400,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setState]);

  return {
    categories,
    setCategories,
    employees,
    lounges,
    isLoading,
  };
};

export default useInitState;
