import { getEmployees } from "@Services/createNewServiceServices";
//You can see this in Luca's repository (facturacion electronica)

export const getEmployeesAdapter = async (setState, setCriticalError) => {
  const employees = await getEmployees();
  if (!employees.isSuccess) {
    setCriticalError(true);
    setState({
      message: employees.message,
      status: 400,
    });
    return [];
  } else {
    return employees.data.map((employee) => {
      const lastName = employee.lastName ? " " + employee.lastName : "";
      const fullName = `${employee.name}${lastName}`;
      return {
        name: fullName,
        id: employee.idEmployee,
      };
    });
  }
};
